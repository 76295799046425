<script setup lang="ts">
import {useRouter} from 'vue-router'

const router = useRouter()
</script>

<template>
    <header
        class="flex align-items justify-between px-6 py-4 border-b-[1px] border-fg font-medium text-sm"
    >
        <router-link to="/" class="text-primary font-bold text-base"
            >Cofco AI</router-link
        >

        <router-link v-if="router.currentRoute.value.path === '/Pro'" to="/" class="text-primary font-bold text-base"
            >Common</router-link
        >
        <router-link v-if="router.currentRoute.value.path === '/'" to="/Pro" class="text-primary font-bold text-base"
            >Pro</router-link
        >
    </header>
</template>
