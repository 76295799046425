<script setup lang="ts">

import {
  onMounted,
  reactive,
  watch,
  ref,
  toRefs,
  computed
} from 'vue'

import Input from "./Input.vue";
// import Range from "./Range.vue";
import Textarea from "./Textarea.vue";
import Checkbox from "./Checkbox.vue";
import Radio from "./Radio.vue";

// import axios from "../utils/axios"
import request from "../utils/request"
// import http from "../utils/api/index"
import { message, Tooltip } from 'ant-design-vue';
import 'ant-design-vue/es/message/style/css'
import 'ant-design-vue/es/tooltip/style/css'
import { LoadingOutlined, ReloadOutlined, CloudDownloadOutlined, RollbackOutlined, ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons-vue';

const step = ref(1)
const loading = ref(false)
const paramsData = ref({
  prompt: '',
  seed: ''
})
const dataInfo = ref({
  prompt: '',
  url: '',
  seed: ''
})

const goImg = async (event:any) => {

  const params: any = {
    // prompt: ''
  }

  const formData:any = new FormData(event.target)
  for (let [a, b] of formData) {
    console.log('所有表单填写的内容：' + a, b);
    if (b?.length>0) {
      params[a] = b;
    }
  }

  console.log(params);
  if(!params || !params.prompt) {
    message.error("请输入描述");
    return
  }
  paramsData.value = params;
  reload(params);
}

const reload = (params:any) => {
  loading.value = true;
  let url = "/pro";
  request(url,{
    data: params,
    method: 'post'
  }).then((res:any) => {
    console.log(res);
    if(res.url?.length>0){
      step.value = 2;
      dataInfo.value = res;
    }else {
      message.error(res);
    }
    loading.value = false;
  })
}

const downloadImage = async (imgsrc:any, name:any) => { // 下载图片地址和图片名
  const image = new Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    // @ts-ignore
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = name || 'photo';
    a.href = url;
    a.dispatchEvent(event);
  };
  image.src = imgsrc;
};

const goBcak = () => {
  step.value = 1;
}

const copyFn = () => {
  // createElement() 方法通过指定名称创建一个元素
  var copyInput = document.createElement("input");
  //val是要复制的内容
  copyInput.setAttribute("value", dataInfo.value.seed);
  document.body.appendChild(copyInput);
  copyInput.select();
  try {
    var copyed = document.execCommand("copy");
    if (copyed) {
      document.body.removeChild(copyInput);
      message.success("复制成功");
    }
  } catch {
    message.error("复制失败，请检查浏览器兼容");
  }
}
</script>

<template>
    <div>
      <form v-if="step === 1" class="flex flex-col md:w-5/12 gap-10 mx-auto my-0 my-phone" @submit.prevent="goImg">
        <ul
            class="relative flex flex-col w-full gap-6 before:absolute before:content-[''] before:z-[-1] before:top-6 before:left-3 before:w-[1px] before:h-[calc(100%-24px)] before:mx-auto before:my-0 before:bg-fg"
        >
          <span class="flex flex-col w-full gap-6 my-0 mx-auto">
                <Textarea
                    number="1"
                    label="绘画描述"
                    placeholder="输入绘画描述"
                    name="prompt"
                    type="text"
                ></Textarea>
          </span>
          <span class="flex flex-col">
              <Input number="2" label="风格Id" placeholder="输入风格 ID，可不填" name="seed" type="text"></Input>
          </span>
        </ul>
        <span class="flex w-full justify-end gap-4 my-phone-button">
            <button
                :disabled="loading"
                type="submit"
                class="px-4 py-2 rounded bg-primary font-semibold text-sm cursor-pointer transition-colors hover:bg-primaryVar"
            >
<!--              <LoadingOutlined />-->
                {{loading ? `AI绘制中...` : '绘制'}}
            </button>
        </span>
      </form>
      <article v-if="step === 2"  class="flex flex-col w-1/2 gap-4 mx-auto my-0 my-phone-90 -my-8">
<!--        flex items-end-->
        <div class="opacity-0 animate-slideDown25 -m-1">
          <div class="w-1/2 m-auto flex justify-between my-phone-80">
            <div class="inline-block">
              <Tooltip placement="top">
                <template #title>
                  <span>返回</span>
                </template>
                <arrow-left-outlined v-on:click="goBcak" class="text-2xl my-4 -mt-8" />
              </Tooltip>
            </div>

            <div class="inline-block text-left">
              <Tooltip placement="top" v-if="loading">
                <template #title>
                  <span>重绘</span>
                </template>
                <LoadingOutlined class="text-2xl m-6 w-6 -mt-8"/>
              </Tooltip>

              <Tooltip placement="top" v-if="!loading">
                <template #title>
                  <span>重绘</span>
                </template>
                <ReloadOutlined v-on:click="reload(paramsData)" class="text-2xl m-6 w-6 -mt-8"/>
              </Tooltip>

              <Tooltip placement="top">
                <template #title>
                  <span>下载</span>
                </template>
                <CloudDownloadOutlined v-on:click="downloadImage(dataInfo.url, paramsData.prompt)" class="text-2xl w-6 -mt-8"/>
              </Tooltip>
            </div>
          </div>
          <img class="w-1/2 mx-auto rounded my-phone-img" :src="dataInfo.url" alt="">
          <p class="my-4 text-right leading-6"><span class="align-middle my-id">风格Id: {{dataInfo.seed}} </span><span class="align-middle cursor-pointer" v-on:click="copyFn"><copy-outlined /></span></p>
        </div>
        <h6 class="text-center w-full border-fg font-bold text-text opacity-0 animate-slideDown -my-4">{{ dataInfo.prompt }}  </h6>
      </article>
    </div>
</template>

<style>
.my-phone-img{
  border: 2px solid #fff;
}
.my-id{
  margin: 8px 10px 0;
  display: inline-block;
}

@media (max-width: 768px) {
  .my-phone{
    width: 80% !important;
  }
  .my-phone-80{
    width: 80% !important;
  }
  .my-phone-90{
    width: 90% !important;
  }
  .my-phone-button{
    margin-bottom: 60px;
  }

  .my-phone-img{
    width: 80% !important;
  }
}
</style>
