<script setup lang="ts">
import RadioOption from "./RadioOption.vue";

import { ref, Ref } from "vue";

interface OptionProps {
  label: string;
  artist: string;
  checked: boolean;
}

defineProps({
  number: String,
  label: String,
  placeholder: String,
});

// {
//   name: "吉卜力工作室",
//       artist: "Studio Ghibli",
//     img: "ai_1"
// },
// {
//   name: "新海诚",
//       artist: "Shinkai Makoto",
//     img: "ai_2"
// },
// {
//   name: "浮世绘",
//       artist: "Ukiyo-e",
//     img: "ai_3"
// },
// {
//   name: "莫奈",
//       artist: "Oscar-Claude Monet",
//     img: "ai_4"
// }

// "吉卜力": "Studio Ghibli",
// "新海诚": "Shinkai Makoto",
// "浮世绘": "Ukiyo-e",
// "皮克斯": "Pixar",
// "莫奈": "Oscar-Claude Monet",
// "梵高": "Vincent Van Gogh",
// "水墨画": "Watercolor",
// "数字艺术": "Digital Art",

const options: Ref<OptionProps[]> = ref([
  {
    label: "吉卜力工作室",
    artist: "Studio Ghibli",
    checked: false,
  },
  {
    label: "新海诚",
    artist: "Shinkai Makoto",
    checked: false,
  },
  {
    label: "浮世绘",
    artist: "Ukiyo-e",
    checked: false,
  },
  {
    label: "皮克斯",
    artist: "Pixar",
    checked: false,
  },
  {
    label: "莫奈",
    artist: "Oscar-Claude Monet",
    checked: false,
  },
  {
    label: "梵高",
    artist: "Vincent Van Gogh",
    checked: false,
  },
  {
    label: "水墨画",
    artist: "Watercolor",
    checked: false,
  },
  {
    label: "数字艺术",
    artist: "Digital Art",
    checked: false,
  }
]);

const isValid = ref();

const handleChecked = (option: OptionProps) => {
  options.value = options.value.map((item) => {
    if (item === option) {
      return { ...item, checked: true };
    }
    return { ...item, checked: false };
  });

  console.log(option);
  console.log(option.artist);

  // const emit = defineEmits<{
  //   (e: 'change', data: ''): void
  // }>()
  //
  // emit('change', option.artist)

  isValid.value = options.value.find((option) => option.checked === true);
};
</script>

<template>
  <li class="group z-10 flex w-full gap-8 my-phone-gap">
    <span
      class="flex min-w-[24px] w-min min-h-[24px] h-min items-center justify-center ring-1 ring-fgVar rounded bg-fg font-medium text-xs transition-all group-focus-within:ring-primary"
      :class="{ 'ring-primary': isValid }"
      >{{ number }}</span
    >
<!--    <div class="flex flex-col w-fit gap-1">-->
    <div class="w-fit gap-1">
      <label for="name" class="w-fit pb-4 font-medium text-sm">{{ label }}</label>
      <div class="flex flex-wrap py-4">
        <RadioOption
          v-for="option in options"
          :label="option.label"
          :name="option.artist"
          :checked="option.checked"
          @change="() => handleChecked(option)"
        ></RadioOption>
      </div>
    </div>
  </li>
</template>


<style>
@media (max-width: 768px) {
  .my-phone-gap {
    gap: 0.8rem;
  }
}
</style>
