<script setup lang="ts"></script>

<template>
    <section class="flex w-full items-center justify-center py-10">
        <h1
            class="text-center py-3 font-extrabold text-6xl text-transparent bg-clip-text bg-gradient-to-r from-primary to-primaryVar"
        >
            Beyond Imagination
        </h1>
    </section>
</template>
