import { createApp } from "vue";
import "./fonts.css";
import "./index.css";
import App from "./App.vue";

import Home from "./views/Home.vue";
import Pro from "./views/Pro.vue";
// import Technologies from "./views/Technologies.vue";

import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", name: "Home", component: Home },
        { path: "/Pro", name: "Pro", component: Pro },
        // {
        //     path: "/technologies",
        //     name: "Technologies",
        //     component: Technologies,
        // },
    ],
});

const app = createApp(App);
app.use(router);
app.mount("#app");
