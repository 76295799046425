import axios from 'axios'
// import {message} from "ant-design-vue";

interface ApiConfig {
    data: object;
    method: any
}

interface AxiosInstance {
    res: any
}

async function request(url: string, options: ApiConfig) {

    // console.log('~~~~~参数：～～～～～～');
    // console.log(options);
    // 创建 axios 实例
    const service = axios.create({
        baseURL: "https://draw-api.uunus.com", // api base_url
        timeout: 10000000, // 请求超时时间
    });
    // 请求拦截
    service.interceptors.request.use(config => {
        // 这里可设置请求头等信息
        if (options?.data) {
            if(options?.method ==='get') {
                // get方法参数放params中
                config.params = options.data;
            }else {
                // post方法参数放data中
                config.data = options.data;
            }
        }
        // console.log(config);
        return config;
    });
    // 返回拦截
    service.interceptors.response.use(response => {
        // 这里可进行返回数据的格式化等操作
        // const res = JSON.parse(response.data);
        const res = response.data;
        if(res.code === 0){
            return res.data;
        }else {
            // message.error(res.msg);
            return res.error;
        }
    });
    return service(url, options);
}
export default request;
